import React from 'react';
import { Helmet } from 'react-helmet';
import styles from './About.module.css';
import aboutImage from '../assets/images/about.webp';

function About() {
  return (
    <div className={styles.aboutContainer}>
      <Helmet>
        <title>About Us - ABC Railing</title>
        <meta name="description" content="Learn more about ABC Railing, a Canadian fabrication company specializing in aluminum, iron & glass railings, staircases, fencing, and gates." />
      </Helmet>
      <img src={aboutImage} alt="ABC Railing" className={styles.aboutImage} />
      <h1 className={styles.title}>About Us</h1>
      <p className={styles.description}>
        Founded in 1959, ABC Railing has transformed and evolved over the decades. Legally known as South Side Manufacturing Ltd., our company was originally recognized under different names that are now synonymous with our competitors. Today, we proudly operate as ABC Railing, standing for the "ABCs of Strong, Safe Metal Railings," highlighting our commitment to excellence in architectural, builder, and commercial projects.
      </p>
      <div className={styles.content}>
        <section aria-label="Products">
          <p className={styles.sectionText}>
            ABC Railing offers a variety of railing systems designed to meet different needs and preferences. Our Architectural Series features high-end railings crafted from premium aluminum and iron alloys, suitable for both exterior and interior use with a sophisticated finish. The Builder Series, or BGRS, is a cost-effective solution tailored for builders, made from durable aluminum alloys available in textured black and gloss white finishes. For commercial applications, our Commercial Series railings are built to meet stringent building codes while ensuring durability and style, providing reliable and compliant solutions for various projects.
          </p>
        </section>

        <section aria-label="Sandhills Group">
          <h2 className={styles.sectionTitle}>The Sandhills Group</h2>
          <p className={styles.sectionText}>
            We are proud to be a part of the Sandhills Group of Companies, a comprehensive network of manufacturing excellence. Besides ABC Railing, our group includes Woodchucks Manufacturing, Sandhills Manufacturing, and Ply-Lam. Together, we offer an integrated solution for your construction needs, ranging from construction-grade stairs to finished-grade stairs and fully-welded aesthetic metal railings and components.
          </p>
          <p className={styles.sectionText}>
            Our commitment to quality is deeply rooted in our Canadian heritage. All our products are meticulously designed in Canada, with the majority manufactured in-house, ensuring the highest standards of craftsmanship and innovation.
          </p>
        </section>

        <section aria-label="Powder Coating Innovation">
          <h2 className={styles.sectionTitle}>Powering Our Innovation: In-House Powder Coating</h2>
          <p className={styles.sectionText}>
            When we first joined the Sandhills Group, we embarked on a transformative journey. We reimagined our operational approach, introducing cutting-edge technologies and streamlining our processes to meet the demands of the modern manufacturing landscape.
          </p>
          <p className={styles.sectionText}>
            One persistent challenge was our powder coating workflow. Commercial powder coaters' extensive wait lists were causing significant delays, sometimes extending project timelines by months. Our solution? In 2022, we established our first in-house powder coating facility—a game-changing move that revolutionized our production capabilities.
          </p>
          <p className={styles.sectionText}>
            This strategic investment allowed us to take complete control of our finishing processes. We gained the flexibility to schedule work on our terms, implemented rigorous quality control measures, and dramatically improved our logistics. The result? A seamless, efficient workflow that delivers exceptional value to our customers.
          </p>
        </section>

        <section aria-label="Builder Website">
          <h2 className={styles.sectionTitle}>Our BGRS Journey</h2>
          <p className={styles.sectionText}>
            At ABC Railing, we pride ourselves on pushing the boundaries of design and engineering. Initially, our premium products faced two challenges: cost and timeline. Though highly sought after for their precision and elegance, the detailed crafting process resulted in higher prices and extended lead times.
            <br /><br />
            <em>Our builder-grade railing system was born out of the need for a cost-effective yet stylish solution tailored for builders.</em>
            <br /><br />
            After months of development, we introduced a revolutionary system that maintains premium aesthetics while being assembled on-site, reducing installation lead times. This innovation not only makes our railings more accessible but also ensures they comply with the National Building Code, providing safety and durability with a luxurious feel.
          </p>
        </section>

        <section aria-label="Commitment">
          <h2 className={styles.sectionTitle}>Our Commitment</h2>
          <div className={styles.commitmentQuote}>
            Right the first time,<br />
            on time,<br />
            <span>every time.</span>
          </div>
          <p className={styles.sectionText}>
            At ABC Railing, we are committed to the principles of "Right the first time, on time, every time." Our dedicated team ensures that every product meets the highest standards and is delivered on schedule, embodying our promise of excellence and reliability.
          </p>
        </section>
      </div>
    </div>
  );
}

export default About;