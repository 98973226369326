import React, { useState, useEffect } from 'react';
import styles from './AnnouncementBar.module.css';

const AnnouncementBar = ({ onClose, visible }) => {
  const [isVisible, setIsVisible] = useState(visible);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const announcementDismissed = sessionStorage.getItem('announcementDismissed');
    if (announcementDismissed === 'true') {
      setIsVisible(false);
      onClose();
    }
  }, [onClose]);

  const handleClose = () => {
    setIsVisible(false);
    sessionStorage.setItem('announcementDismissed', 'true');
    onClose();
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (!isVisible) return null;

  return (
    <>
      <div className={styles.announcementBar}>
        <div className={styles.content}>
          <span>
            South Side Manufacturing is now <strong>ABC Railing</strong>! 
            <button 
              onClick={handleOpenModal} 
              className={styles.learnMoreBtn}
            >
              Learn More
            </button>
          </span>
          <button 
            onClick={handleClose} 
            className={styles.closeBtn}
            aria-label="Close announcement"
          >
            &times;
          </button>
        </div>
      </div>

      {isModalOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <button onClick={handleCloseModal} className={styles.closeModalIcon} aria-label="Close modal">
              &times;
            </button>
            <h2>Exciting News: We're Now ABC Railing</h2>
            <p>
              We're thrilled to announce that South Side Manufacturing, formerly known as South Side Ornamental, 
              has rebranded as ABC Railing. South Side Manufacturing Ltd. remains our legal name. This change reflects our continued commitment to innovation, 
              quality, and customer satisfaction.
            </p>
            <p>
              Our core values remain the same: delivering exceptional railing solutions that combine 
              durability, design, and craftsmanship. While our name has changed, our dedication to 
              serving you remains unwavering.
            </p>
            <p>
              We look forward to continuing our journey with you under the ABC Railing brand.
            </p>
            <p>
              *Please take note of our updated email addresses. We can be reached at <a href="mailto:hello@abcrailing.com">hello@abcrailing.com</a> for all your enquiries.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default AnnouncementBar;